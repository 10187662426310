import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, } from "react-redux";
import { Button, Card, CardHeader, CardBody, Container, Row } from "reactstrap";
import confirm from "reactstrap-confirm";
import style from "./UploadRewardAndRecognition.module.scss";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { notify } from "../../../utils/helpers";
import { addRewardAndRecognition } from "../../../store/slices/RewardAndRecognition/RewardAndRecognitionActions";

const UploadRewardAndRecognition = () => {
  const { t } = useTranslation();
  const notificationAlertRef = useRef(null);

  const [pdfFile, setPdfFile] = useState(null);
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setPdfFile(file);
  };
  const msgAlert = (type, msg) => {
    notify(type, msg, notificationAlertRef, t("submittedSuccessfully"));
  }
  const onUpload = async () => {
    let result = await confirm({
      title: <>Warning</>,
      message: t("r&r.uploadfile"),
      confirmText: "Confirm",
      confirmColor: "primary",
      cancelColor: "link text-danger",
    });
    if (result)
      dispatch(addRewardAndRecognition({ file: pdfFile, alertFun: msgAlert, t }));
  }
  useEffect(() => {
  }, []);

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <SimpleHeader name={t("idea.ideas")} parentName={t("idea.ideasManagement")} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">{t("r&r.uploadYourFile")}</h3>
              </CardHeader>
              <CardBody className={style.cardBody}>
                <input type="file" accept=".pdf" onChange={handleFileChange} />
                <Button type="button" color="primary" onClick={onUpload}>{t("r&r.upload")}</Button>
              </CardBody>
            </Card>
          </div >
        </Row >
      </Container >
    </>
  );
};
export default UploadRewardAndRecognition;
