import makeAnimated from "react-select/animated";
import confirm from "reactstrap-confirm";
import { generateRefreshToken } from "../store/slices/auth/authActions";
import { cloneObj } from "../shared/utils";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";

export function notify(type, text, ref, label) {
  let options = {
    place: "tc",
    message: (
      <div className="alert-text">
        <span className="alert-title" data-notify="title">
          {label}
        </span>
        <span data-notify="message">{text}</span>
      </div>
    ),
    type: type,
    icon: "ni ni-bell-55",
    autoDismiss: 7,
  };
  ref.current.notificationAlert(options);
}

export const animatedComponents = makeAnimated();

export function getRelicationColor(state) {
  if (state === "Fully Replicated") {
    return "success";
  } else if (state === "Partially Replicated") {
    return "warning";
  } else if (state === "Can't Be Replicated") {
    return "default";
  } else {
    return "danger";
  }
}

export function getStatusColor(val) {
  if (/true/i.test(val)) {
    return "success";
  } else if (/false/i.test(val)) {
    return "default";
  } else if (/hold/i.test(val)) {
    return "warning";
  } else {
    return "danger";
  }
}

export function sortFilterArray(array, key) {
  if (array.length) {
    const clonedArray = JSON.parse(JSON.stringify(array));
    clonedArray?.sort(function (a, b) {
      if (a[key]?.[0]?.name.en < b[key]?.[0]?.name.en) {
        return -1;
      }
      if (a[key]?.[0]?.name.en > b[key]?.[0]?.name.en) {
        return 1;
      }
      return 0;
    });
    return clonedArray;
  }
}

export function deleteZeroProps(filterObj) {
  for (let item in filterObj) {
    if (filterObj[item]?.$in?.length === 0) delete filterObj[item];
  }
}

function initRefreshToken({ decoded, token, dispatch, notificationAlertRef }) {
  dispatch(generateRefreshToken({ email: decoded.email, oldToken: token }));
  notify("success", "Your token has been refreshed.", notificationAlertRef);
}

export async function checkJwtExpiration(
  token,
  decoded,
  dispatch,
  history,
  notificationAlertRef
) {
  if (token) {
    if (document.hidden) {
      logoutAndRemoveToken();
      history.go(0);
    } else {
      const refresh = await confirm({
        title: <>Warning</>,
        message: "Your token has ended, do you want to reshresh it?",
        confirmText: "Confirm",
        confirmColor: "primary",
        cancelColor: "link text-danger",
      });
      if (refresh) {
        initRefreshToken({ decoded, token, dispatch, notificationAlertRef });
        history.go(0);
      } else {
        logoutAndRemoveToken();
        history.go(0);
      }
    }
  }
}

function logoutAndRemoveToken() {
  localStorage.removeItem("opexDashbaordToken");
  localStorage.removeItem("user");
}

export function filterValuesInIdeasFilter(
  arrayToFilter,
  key,
  filterArray,
  single
) {
  if (
    !single &&
    (filterArray[key] === undefined || filterArray[key]?.length === 0)
  ) {
    return arrayToFilter;
  }
  if (!arrayToFilter?.length) {
    return [];
  }
  let filterIds = [];
  if (!single) {
    filterIds = filterArray?.[key]?.map((filter) => filter.value) ?? [];
  } else {
    filterIds.push(filterArray);
  }
  return arrayToFilter?.filter((element) => {
    return element?.[key]?.some((prop) => filterIds.includes(prop?._id));
  });
}
/**
 *
 * @param {string} key  type of status to be changed
 * @param {string} val  property of the status to be changed
 * @param {number} savings  amount of savings
 * @param {{}} myStatus  object that needs to be mutated
 * @param {function} setStatus  setState func
 */

export function updateStatusValues(key, val, savings, myStatus, setStatus) {
  const newState = cloneObj(myStatus);
  switch (val) {
    case "canBeReplicated":
      newState[key].canBeReplicated = !myStatus[key].canBeReplicated;
      setStatus(newState);
      break;
    case "value":
      if (key === "rewarded") newState[key].accepted = !myStatus[key].value;
      newState[key].value = !myStatus[key].value;
      newState[key].date = new Date();
      setStatus(newState);
      break;
    case "savings":
      newState[key].savings = +savings;
      newState[key].date = new Date();
      setStatus(newState);
      break;
    case "eventName":
    case "eventDate":
      newState[key][val] = savings;
      setStatus(newState);
      break;
    default:
      break;
  }
}
/**
 * Validates an input value based on its type and returns an error message if invalid.
 *
 * @param {any} value - The input value to be validated.
 * @param {string} type - The type of input value ('array', 'text', or 'number').
 * @param {string} errorMessage - The error message to return if validation fails.
 * @param {string} language - The selected language ('en' for English, 'ar' for Arabic).
 * @returns {string} An error message if validation fails, otherwise an empty string.
 */
function validateInput(value, type, language, errorMessage) {
  const requiredMessage = {
    en: "This field is required",
    ar: "هذا الحقل مطلوب",
  };

  if (type === "array" && value.length === 0) {
    return requiredMessage[language] || errorMessage;
  } else if (type === "text" && !value.trim()) {
    return requiredMessage[language] || errorMessage;
  } else if (type === "number" && value === "") {
    return requiredMessage[language] || errorMessage;
  } else {
    return;
  }
}
/**
 * Validates a value and adds an error message to the specified errors object if the validation fails.
 *
 * @param {Object} errors - The errors object where the error message should be added.
 * @param {string} key - The key to use when adding the error property to the errors object.
 * @param {any} value - The value to validate.
 * @param {string} type - The type of validation to perform (e.g., 'array', 'text', 'number').
 * @param {string} language - The language code for error message localization.
 * @param {string} errorMessage - The custom error message to use if validation fails.
 */
export function validateAndAddError(
  errors,
  key,
  value,
  type,
  language,
  errorMessage
) {
  const validationResult = validateInput(value, type, language, errorMessage);
  if (validationResult !== undefined) {
    errors[key] = validationResult;
  }
}

/**
 * Downloads data from a specified API URL and saves it as a file with the given filename.
 *
 * @async
 * @param {string} apiUrl - The URL of the API to fetch data from.
 * @param {string} target - Target the function is called.
 *
 * @throws {Error} If an error occurs during the download or file creation.
 */
export async function downloadAsFile(apiUrl, target) {
  try {
    const response = await fetch(apiUrl);

    if (response.ok) {
      const data = await response.json();
      const jsonData = JSON.stringify(data, null, 2);

      // Prompt the user for a filename
      const fileName = window.prompt(
        "Enter a filename for the JSON file",
        `${target} backup.json`
      );

      if (fileName) {
        // Check if the user provided a filename or clicked "Cancel"
        // Create a blob containing the JSON data
        const blob = new Blob([jsonData], { type: "application/json" });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary `a` tag and trigger a click to initiate the download
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        // Use the provided filename
        a.download = fileName;

        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } else {
      console.error("Download failed");
    }
  } catch (error) {
    console.error("Error:", error);
  }
}

export function getUserType() {
  return JSON.parse(localStorage.getItem("user"))?.userType;
}

export function getFilterFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem("filterObject"))?.[key];
}

export function resetFilterFromLocalStorage() {
  localStorage.removeItem("filterObject");
}

/**
 * Handles the logic for tracking and managing the number of open tabs,
 * as well as updating session activity status based on token and page reload.
 *
 * @param {string} token - The token to check for session activity.
 * @returns {boolean|null} - The current session activity status stored in localStorage.
 */
export function handleRefreshAndTabsOpen(token) {
  // define increment counter part
  const tabsOpen = localStorage.getItem("tabsOpen");
  if (tabsOpen === null) {
    localStorage.setItem("tabsOpen", 1);
  } else {
    localStorage.setItem("tabsOpen", +tabsOpen + +1);
  }

  // define decrement counter part
  window.onunload = function () {
    const newTabCount = localStorage.getItem("tabsOpen");
    if (newTabCount !== null) {
      localStorage.setItem("tabsOpen", newTabCount - 1);
    }
  };
  if (
    token &&
    performance.getEntriesByType("navigation")[0].type === "reload"
  ) {
    localStorage.setItem("isMySessionActive", "true");
  } else {
    const newTabCount2 = localStorage.getItem("tabsOpen");
    let value = localStorage.getItem("isMySessionActive");
    if (value === "true") {
      if (newTabCount2 - 1 === 0) {
        localStorage.removeItem("isMySessionActive");
        localStorage.removeItem("tabsOpen");
      } else {
        localStorage.setItem("isMySessionActive", "true");
      }
    }
  }
  return localStorage.getItem("isMySessionActive");
}
/**
 * Finds duplicate employee codes from an array of objects containing employee data.
 * @param {Array<{code: number, name: string}>} data - The array of employee data objects.
 * @returns {string} A string containing the duplicate employee codes, separated by commas.
 */
export function findEmployeeCodeDuplicates(data) {
  const uniqueCodes = new Set();
  const duplicateCodes = new Set();
  const result = [];

  // Iterate through the data array to find duplicate codes
  data.length &&
    data.forEach((obj) => {
      if (uniqueCodes.has(obj.code)) {
        // If code is already in uniqueCodes set, add it to duplicateCodes set
        duplicateCodes.add(obj.code);
      } else {
        // If code is not in uniqueCodes set, add it to uniqueCodes set and add the object to result array
        uniqueCodes.add(obj.code);
        result.push(obj);
      }
    });

  // Return a string containing the duplicate codes, separated by commas
  return [...duplicateCodes].join(", ");
}

export const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

/**
 * Exports an array of objects (ideas) to an Excel file and triggers a download.
 *
 * This function converts the provided JSON data into an Excel worksheet,
 * creates a workbook, and generates a downloadable Excel file (.xlsx format).
 *
 * @param {Object[]} ideas - An array of objects representing the data to be exported.
 * Each object represents a row, and the keys represent the column headers.
 * Example:
 * [
 *   { id: 1, title: 'Idea 1', description: 'Description of idea 1' },
 *   { id: 2, title: 'Idea 2', description: 'Description of idea 2' }
 * ]
 * @param {string} fileName - The name of the Excel file (without the .xlsx extension)
 * that will be downloaded.
 *
 * @example
 * // Example usage:
 * const ideas = [
 *   { id: 1, title: 'Idea 1', description: 'Description of idea 1' },
 *   { id: 2, title: 'Idea 2', description: 'Description of idea 2' }
 * ];
 * exportExcelFile(ideas, 'MyIdeas'); // Downloads 'MyIdeas.xlsx'
 */
export const exportExcelFile = (ideas, fileName) => {
  // Convert JSON data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(ideas);

  // Set all columns to a width of 30 characters
  worksheet["!cols"] = Array(Object.keys(ideas[0]).length).fill({ wch: 30 });

  // Define the style for the header cells: light blue background, white text, centered
  const headerStyle = {
    font: { bold: true, color: { rgb: "FFFFFF" } }, // White text
    fill: { fgColor: { rgb: "ADD8E6" } }, // Light blue background
    alignment: { horizontal: "center", vertical: "center" }, // Centered text
  };

  // Apply the header style to each header cell dynamically
  const headerCells = Object.keys(ideas[0]);
  headerCells.forEach((_, index) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index }); // Encode the cell address (e.g., A1, B1)
    if (!worksheet[cellAddress]) return; // Ensure the cell exists
    worksheet[cellAddress].s = headerStyle; // Apply style
  });

  // Create a new workbook and append the styled worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

  // Generate a binary string representation of the workbook
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
    cellStyles: true, // Enable cell styles
  });

  // Create a Blob from the binary string
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });

  // Create a download link and trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", `${fileName}.xlsx`); // Set the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
