/*eslint-disable*/
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ar from "./ar.json";
import en from "./en.json";
import urdu from "./urdu.json";

export const languages = ["ar", "en", "urdu"];
let defaultLanguage = "ar";
const currentLang = localStorage.getItem("i18nextLng");
if (currentLang) {
  defaultLanguage = currentLang;
}
const getLang = () => {
  return defaultLanguage;
};
const languageDetector = new LanguageDetector();
i18n
  .use(languageDetector) // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    resources: { ar, en, urdu },
    lng: getLang(),
    //detection:{lookupQuerystring: 'lang'},
    fallbackLng: defaultLanguage,
    ns: ["translation"],
    defaultNS: "translation",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
