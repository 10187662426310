
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Container, Row } from "reactstrap";
import { API_URLS } from "../../../constants/API_URLS";
import { getRewardAndRecognitions } from "../../../store/slices/RewardAndRecognition/RewardAndRecognitionActions";
import style from "./RewardsAndRecognition.module.scss";

export default function RewardsAndRecognition() {
  const { t } = useTranslation();
  const notificationAlertRef = useRef(null);
  const dispatch = useDispatch();
  const pdfFiles = useSelector(
    (state) => state?.RewardAndRecognition?.RewardAndRecognitions
  );
  const pdfFileURI = pdfFiles[0]?.fileId[0] ? API_URLS.File.viewPDF + pdfFiles[0].fileId[0] : null;


  const openPdfInNewTab = () => {
    if (pdfFileURI) {
      window.open(pdfFileURI, '_blank');
    }
  };
  useEffect(() => {
    dispatch(getRewardAndRecognitions());
  }, [])

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className={style.pageContainer}>
        <Container className="pt-6">
          <Row className="justify-content-center">
            <Col xs="12" md="6">
              <Card className="bg-secondary border-0 mb-0">

              </Card>
            </Col>
          </Row>
        </Container>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
          {pdfFileURI && (
            <>
              <div style={{ width: '80%', maxWidth: '1000px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
                <embed
                  style={{ borderRadius: '10px' }}
                  title="PDF Viewer"
                  width="100%"
                  height="1200px"
                  src={pdfFileURI}
                  sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                />
              </div>
              <Button color="secondary" onClick={openPdfInNewTab} style={{ margin: '20px' }}>
                Open PDF in New Tab
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

