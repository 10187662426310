import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import AuthHeader from "../../../components/Headers/AuthHeader";
import { signupUser } from "../../../store/slices/auth/authActions";
import { getBusinessUnitNames } from "../../../store/slices/factoryMinibusinessUnit/factoryMinibusinessUnitActions";
import { useAppDispatch } from "../../../store/store";
import { notify } from "../../../utils/helpers";
import confirm from "reactstrap-confirm";

const Register = () => {
  const notificationAlertRef = useRef(null);
  const appDispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const businessUnitNames =
    useSelector((state) => state.factoryBusinessUnit.businessUnitNames) || [];

  const [formData, setFormData] = useState({
    username: "",
    userCode: "",
    mobile: "",
    email: "",
    password: "",
    factoryBusinessUnit: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      if (!/^\d+$/.test(value)) {
        e.target.setCustomValidity(
          "Please enter numbers only for the phone number."
        );
      } else {
        e.target.setCustomValidity("");
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let result = await confirm({
        title: <>Warning</>,
        message: "Are you sure you want to submit the registration?",
        confirmText: "Confirm",
        confirmColor: "primary",
        cancelColor: "link text-danger",
      });
      if (result) {
        formData.email = formData.email.toLowerCase();
        const res = await appDispatch(signupUser(formData));
        if (res.error) {
          notify(
            "danger",
            `${res.payload.message}`,
            notificationAlertRef,
            "Something went wrong"
          );
        } else {
          notify(
            "success",
            "Registered Successfully",
            notificationAlertRef,
            "Success"
          );
          setTimeout(() => {
            history.push("/admin/dashboard");
            history.go(0);
          }, 2500);
        }
      }
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    appDispatch(getBusinessUnitNames());
  }, []);
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <AuthHeader title={t("createAccount")} lead={t("kindlyLogin")} />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2 className="text-muted">{t("signUp")}</h2>
                </div>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="username"
                      id="username"
                      placeholder={t("register.name")}
                      value={formData.username}
                      onChange={handleChange}
                      required
                      minLength="4"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="text"
                      name="userCode"
                      id="userCode"
                      placeholder={t("register.userCode")}
                      value={formData.userCode}
                      onChange={handleChange}
                      minLength="4"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="tel"
                      name="mobile"
                      id="mobile"
                      placeholder={t("register.mobileNo")}
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                      minLength="11"
                      pattern="[0-9]+"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t("register.email")}
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder={t("register.password")}
                      value={formData.password}
                      onChange={handleChange}
                      required
                      minLength="8"
                      maxLength="20"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Select2
                      required
                      value={formData.factoryBusinessUnit}
                      name="factoryBusinessUnit"
                      id="factoryBusinessUnit"
                      onSelect={handleChange}
                      options={{
                        placeholder: t("register.selectFactory"),
                      }}
                      data={businessUnitNames?.map((el) => {
                        return {
                          id: el._id,
                          value: el._id,
                          text: el.name[i18n.language] || el.name?.["en"],
                        };
                      })}
                    />
                    {/* <Label for="factoryBusinessUnit">Factory</Label>
                    <Input
                      type="select"
                      name="factoryBusinessUnit"
                      id="factoryBusinessUnit"
                      value={formData.factoryBusinessUnit}
                      onChange={handleChange}
                      required
                    >
                      {businessUnitNames.map((factory) => (
                        <option key={factory._id} value={factory._id}>
                          {factory.name[i18n.language]}
                        </option>
                      ))}
                    </Input> */}
                  </FormGroup>

                  <Button color="primary" type="submit" className="center">
                    Register
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Register;
