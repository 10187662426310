import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import style from "./LandingPage.module.scss";
import AICLogo from "../../../assets/img/brand/AICLogo2.png";
import AuthNavbar from "components/Navbars/AuthNavbar.js";

function LandingPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const notificationAlertRef = React.useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("opexDashbaordToken");
    if (token) history.push("/admin/dashboard");
  });

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <AuthNavbar />
      <div className={style.landingPageContainer}>
        <Container className={style.landingPageContent}>
          <Row className="justify-content-center">
            <Col xs={10}>
              <h1 className="text-white text-center ">{t("welcome")}</h1>
              <Card className="bg-secondary border-0 mb-0 mt-5">
                <CardBody className="px-lg-5 pb-lg-5 ">
                  <h2 className="text-center mb-4">
                    {t("Idea Management System(IMS)")}
                  </h2>
                  <div className={style.landingPageCardBody}>
                    <div className={style.landingPageCardBodyLeft}>
                      <Link to="/auth/form">
                        <h3>{t("submitYourIdea")}</h3>
                      </Link>
                      <Link to="/" className={style.disabledLink}>
                        <h3 className="text-muted">{t("submitYourProject")}</h3>
                      </Link>
                      <Link to="/" className={style.disabledLink}>
                        <h3 className="text-muted">{t("knowledge")}</h3>
                      </Link>
                    </div>
                    <div className={style.landingPageCardBodyRight}>
                      <Link to="/auth/ideaStatus">
                        <h3>{t("ideaStatus")}</h3>
                      </Link>
                      <Link to="/" className={style.disabledLink}>
                        <h3 className="text-muted">{t("projectStatus")}</h3>
                      </Link>
                      <div className={style.CardLogos}>
                        <img
                          src={AICLogo}
                          width={200}
                          height={100}
                          alt="AICLogo"
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPage;
