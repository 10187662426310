import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

const LanguageSelect = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLanguageSelect = (language) => {
        setTimeout(() => {
            history.push("/");
            history.go(0);
            i18n.changeLanguage(language);
            setIsOpen(false);
        }, 1000)
    };

    return (
        <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
            <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={isOpen}
                style={{
                    backgroundColor: "transparent",
                    cursor: "pointer",
                }}
            >
                {t('lang.changeLanguage')}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => handleLanguageSelect("en")}>
                    {t('lang.english')}
                </DropdownItem>
                <DropdownItem onClick={() => handleLanguageSelect("ar")}>
                    {t('lang.arabic')}
                </DropdownItem>
                <DropdownItem onClick={() => handleLanguageSelect("urdu")}>
                    {t('lang.urdu')}
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default LanguageSelect;
