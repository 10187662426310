export function UserTypeSelect(t) {
  return [
    {
      id: "ADMIN",
      value: "ADMIN",
      text: t("ADMIN"),
    },
    {
      id: "CONSULTANT",
      value: "CONSULTANT",
      text: t("CONSULTANT"),
    },
    {
      id: "KEY_OWNER",
      value: "KEY_OWNER",
      text: t("KEY_OWNER"),
    },
    {
      id: "PROJECT_TEAM",
      value: "PROJECT_TEAM",
      text: t("PROJECT_TEAM"),
    },
    {
      id: "KEY_LEADER",
      value: "KEY_LEADER",
      text: t("KEY_LEADER"),
    },
    {
      id: "HR",
      value: "HR",
      text: t("HR"),
    },
    {
      id: "EMPLOYEE",
      value: "EMPLOYEE",
      text: t("EMPLOYEE"),
    },
  ];
}
